import React from 'react';
import PropTypes from 'prop-types';

// Helpers
import errorMessages from './errorMessages';

const propTypes = {
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    children: PropTypes.node,
    className: PropTypes.string
};

const FormGroupError = (props) => {
    const {
        error,
        children,
        className,
        ...attributes
    } = props;
    const errorClass = (error === null || error === undefined) ?  "" : "field_with_errors";
    const finalClass = className !== undefined ? `${className} ${errorClass}` : `${errorClass}`
    return (
        <div {...attributes} className={ finalClass }>
            {children}
            {error && errorMessages(error)}
        </div>
    );
};


FormGroupError.propTypes = propTypes;

export default FormGroupError;