import request from './../../utils/request'

const addressData = form => {
  const data = new FormData();
  data.append('customer_address[address_type]', form.address_type);
  data.append('customer_address[name]', form.name);
  data.append('customer_address[is_default]', form.is_default);
  data.append('customer_address[receiver_type]', form.receiver_type);
  data.append('customer_address[receiver_name]', form.receiver_name);
  data.append('customer_address[phone_number]', form.phone_number);
  data.append('customer_address[country_id]', form.country_id);
  data.append('customer_address[city]', form.city);
  data.append('customer_address[district]', form.district);
  data.append('customer_address[zip_code]', form.zip_code);
  data.append('customer_address[tax_office]', form.tax_office);
  data.append('customer_address[tax_number]', form.tax_number);
  data.append('customer_address[address]', form.address);
  data.append('customer_address[corporate_name]', form.corporate_name);
  data.append('customer_address[identity_number]', form.identity_number);
  data.append('customer_address[add_as_another_address]', form.add_as_another_address);
  data.append('customer_address[is_default]', form.is_default);
  // if( form.add_as_another_address === "yes" ){
  //   data.append('customer_address[add_as_another_address]', form.add_as_another_address);
  // }
  return data
};

export const AddressActions = {
  create: (form, call = null) => {
    const data = addressData(form);
    return request(`/user/${form.address_type}_addresses`, call, {
      method: 'POST',
      body: data
    });
  },
  update: (form, call = null) => {
    const data = addressData(form);
    return request(`/user/${form.address_type}_addresses/${form.id}`, call, {
      method: 'PUT',
      body: data
    });
  }
};

export default AddressActions;
