import React from 'react';

const errorMessages = (error) => {
    let message = error;
    if (typeof error === "object") {
        message = error.reduce((prev, curr) => `${prev}, ${curr}`);
    }
    return (
        <span className="text-custom-red text-sm">
            {message}
        </span>
    );
};

export default errorMessages;