export const PRICE_REQUIRED_DELIVERY_TYPES = ["cargo", "courier"];
export const ADDRESS_REQUIRED_DELIVERY_TYPES = ["cargo", "courier"];
export const DELIVERY_TYPE_CARGO = "cargo";
export const DELIVERY_TYPE_COURIER = "courier";
export const DELIVERY_TYPE_SCANNED = "scanned";
export const DELIVERY_TYPE_NONE = "none";

export const PAYMENT_TYPE_IYZICO = "iyzico";
export const PAYMENT_TYPE_TRANSFER = "transfer";
export const PAYMENT_TYPE_TRANSFER_VAKIFBANK = "vakifbank";
export const PAYMENT_TYPE_STRIPE = "stripe";
export const PAYMENT_TYPE_CURRENT_ACCOUNT = "current_account";
export const PAYMENT_TYPE_PAYMENT_WAY = "payment_way";

export const ADDRESS_TYPE_INVOICE = "invoice";
export const ADDRESS_TYPE_DELIVERY = "delivery";

export const PACKAGE_STANDARD = "standard";

export const DELIVERY_SPEED_STANDARD = "standard";
export const DELIVERY_SPEED_FAST = "fast";

// Domains
export const DOMAIN_HIZLICEVIRI = "hizliceviri";
