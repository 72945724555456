import { Controller } from "@hotwired/stimulus";
import { Accordion } from "flowbite";

// create an array of objects with the id, trigger element (eg. button), and the content element
// const accordionItems = [
//   {
//     id: "blog-post-heading-1",
//     triggerEl: document.querySelector("#blog-post-heading-1"),
//     targetEl: document.querySelector("#blog-post-body-1"),
//     active: true,
//   },
// ];
//
// // options with default values
// const options = {
//   alwaysOpen: false,
//   activeClasses: "accordion-active",
//   inactiveClasses: "accordion-inactive",
// };

export default class extends Controller {
  connect() {
    const targetEl = document.querySelector(this.element.dataset.accordionTarget);
    const id = targetEl.getAttribute('aria-labelledby');
    const triggerEl = document.getElementById(id);
    const options = {
      activeClasses: triggerEl.parentElement.dataset.activeClasses,
      inactiveClasses: triggerEl.parentElement.dataset.inactiveClasses,
    };
    
    const accordionItems = [
      {
        id: id,
        triggerEl: triggerEl,
        targetEl: targetEl,
        active: this.element.getAttribute('aria-expanded') === "true",
      },
    ];
    const accordion = new Accordion(accordionItems, options);
  }
}
