export const requestV2 = (path, options = {}) => {
  const opts = {
    headers: {
      'Accept': 'application/json',
      'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content
    },
    credentials: 'include',
    method: 'GET'
  }
  const newOptions = {...opts, ...options};
  return fetch(path, newOptions)
  .then((response) => {
    if (response.ok) {
      return response.json()
    } else if (response.status == 401) {
      return response.json()
    } else {
      return Promise.reject(response)
    }
  })
};

export const request = (path, call, options = {}) => {
  requestV2(path, options)
  .then((json) => {
    // console.warn(call);
    if (call !== null) {
      return call(json);
    }
    return json;
  }).catch(err => {
    console.warn(err);
    if (call !== null) {
      return call({success: false, error: I18n.t.error_occurred_in_request, error_messages: []});
    }
    console.log("Error occurred in request!");
    console.log("Error :", err);
  })
};

export default request;